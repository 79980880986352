import React from "react";
import styled from "@emotion/styled";

const Section = styled.section`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
  background: var(--raisin);
`;

const CenterText = styled.text`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* background: rgba(0,0,0,0.75); */
  h1 {
    font-size: 2rem;
    color: white;
    text-align: center;
  }

  h3 {
    font-size: 2rem;
    color: white;
    margin: 2rem;
  }
`;

function News() {
  return (
    <Section>
      <CenterText>
        <h1>密切留意</h1>
      </CenterText>
    </Section>
  );
}

export default News;
