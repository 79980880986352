import React from "react";
import BBCow from "../images/BBCow.png";

import styled from "@emotion/styled";
import { APP_CONSTANTS } from "../data/data";

import beefImg from "../images/main.jpg";
import potImg from "../images/s8-side.jpg";
import vLogo from "../images/logo-vertical.png";

const Section = styled.section`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const Img = styled.img`
  width: 100%;
  height: 100vh;
  /* margin: 0;
  padding: 0; */
  object-fit: cover;
  /* position: absolute; */
  overflow-y: hidden;
`;

const Logo = styled.img`
  width: 30%;
  z-index: 100;
`;

const CenterText = styled.h1`
  position: absolute;
  /* width: 100%; */
  /* top: 20%; */
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4vw;
  text-align: center;
  color: white;
  padding: 0;
  text-shadow: 2px 3px var(--gold);
  /* margin: 0; */
`;

// const Text = styled.text`
//   position: absolute;
//   left: 0;
//   top: 50%;
//   background: rgba(0, 0, 0, 0.75);
//   h3 {
//     font-size: 2rem;
//     color: white;
//     margin: 2rem;
//   }
// `;

const H3 = styled.h3`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
`;
const logoContainer = styled.div`
  position: absolute;
  width: 30vh;
  height: 50vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
`;

const Vlogo = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255,255,255,0.95);
  /* border: 1px solid var(--raisin); */
  /* width: 30vh; */
  height: 40vh;
  padding: 10vw;
`;

function Home() {
  return (
    <div>
      <Section>
        <Img src={potImg} alt="banner" />

        <Vlogo src={vLogo} alt="logo" />

        {/* <Text>
          <h3>{APP_CONSTANTS.name_zh}</h3>
        </Text> */}
        {/* <CenterText>{APP_CONSTANTS.name_zh}</CenterText> */}
        {/* <H3>tekslja</H3> */}
      </Section>

      {/* <Logo src={BBCow} alt="logo" /> */}
    </div>
  );
}

export default Home;
