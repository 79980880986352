import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";

import Container from "@mui/material/Container";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import logo from "../images/logo-vertical-black.png";
import { Typography } from "@mui/material";
import { APP_CONSTANTS } from "../data/data";
function Footer() {
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "var(--raisin)", height: "auto" }}
    >
      <Container maxWidth="xl">
        <Toolbar>
          {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box> */}
          <Box
            sx={{
              flexGrow: 1,
              //   display: { xs: "flex", md: "none" },
              //   justifyContent: "center",
            }}
          >
            <img src={logo} alt="logo" style={{ height: "100px" }} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              //   display: { xs: "flex", md: "none" },
              //   typography: "caption",
              color: "white",
            }}
          >
            <Typography variant="caption" component="div">
              電郵: {APP_CONSTANTS.email}
            </Typography>
            <Typography variant="caption" component="div">
              訂座電話 : {APP_CONSTANTS.booking_phone}
            </Typography>
            <Typography variant="caption" component="div">
              營業時間: {APP_CONSTANTS.opening_hour}
            </Typography>
            <Typography variant="caption" component="div">
              地址: {APP_CONSTANTS.address_zh}
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "block" },
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <a
              href="https://www.instagram.com/beefbang_hk/"
              style={{ textDecoration: "none", color: "white" }}
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/beefbanghk"
              style={{ textDecoration: "none", color: "white" }}
            >
              <FacebookIcon />
            </a>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Footer;
