import logo from "./logo.svg";
import "./App.css";
import styled from "@emotion/styled";

import { Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar";
import Footer from "./components/footer";

import Home from "./components/home";
import Menu from "./components/menu";
import News from "./components/news";
import About from "./components/about";
import Soup from "./components/soup";

import { useState } from "react";
// import Popup from "reactjs-popup";
// import discount88 from "./images/discount-88.jpg";

function App() {
  const [open, setOpen] = useState(true);
  const closePopup = () => setOpen(false);

  return (
    <div className="App">
      {/* <Popup open={open} closeOnDocumentClick onClick={closePopup}>
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={discount88}
            alt="discount 88"
            style={{ maxWidth: '90vw', maxHeight: '70vh' }}
            onClick={closePopup}
          />
        </div>
      </Popup> */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/soup" element={<Soup />} />
        <Route path="/news" element={<News />} />
        <Route path="/about" element={<About />} />
        <Route path="/menu" element={<Menu />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
