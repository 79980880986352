import React from "react";
import styled from "@emotion/styled";
import { APP_CONSTANTS } from "../data/data";
import bgImage from "../images/bg.jpg";
import { InView } from "react-intersection-observer";
import { motion } from "framer-motion";

const fadeVariants = {
  hidden: { opacity: 0, transition: { duration: 1 } },
  visible: { opacity: 1, transition: { duration: 1 } },
};

const Section = styled.section`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
  background-image: url("../images/bg.jpg");
  background-repeat: repeat;
  background-size: cover;
`;

const Img = styled.img`
  width: 100%;
  height: 95%;
  object-fit: cover;
  /*position: absolute; */
`;

const LeftText = styled(motion.text)`
  position: absolute;
  left: 0;
  top: 50%;
  background: rgba(0, 0, 0, 0.75);
  /* opacity: inView ? 1 : 0  */
  h3 {
    font-size: 2rem;
    color: white;
    margin: 2rem;
    padding-left: 3rem;
    text-decoration-line: underline;
    text-underline-offset: 10px;
    text-decoration-color: var(--gold);
  }
`;

const RightText = styled.text`
  position: absolute;
  right: 0;
  top: 50%;
  background: rgba(0, 0, 0, 0.75);
  /* transform: skew(-20deg); */
  h3 {
    font-size: 2rem;
    color: white;
    margin: 2rem;
    padding-right: 3rem;
    /* text-decoration: underline; */
    text-decoration-line: underline;
    text-underline-offset: 10px;
    text-decoration-color: var(--gold);
    /* background-image: linear-gradient(var(--gold), var(--raisin)),
    linear-gradient(rgb(176, 251, 188), rgb(176, 251, 188)),
    linear-gradient(#feb2b2, #feb2b2);
    background-size: 20px 2px, 100% 2px, 0 2px;
    background-position: calc(20px * -1) 100%, 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 2s linear, background-position 2s linear; */
  }
  h3:hover {
    /* background-size: 20px 2px, 0 2px, 100% 2px;
  background-position: calc(100% + 20px) 100%, 100% 100%, 0 100%; */
  }
`;

// const H3 = styled.h3`
//   position: absolute;
//   left: 0;
//   top: 50%;
//   transform: translate(0, -50%);
// `;

function Soup() {
  // const { ref, inView, entry } = useInView({ threshold: 0 });
  // console.log("menu", inView);

  return (
    <div>
      {APP_CONSTANTS.pot.map((soup, idx) => (
        // <InView>
        //   {(ref, inView) => (
            <Section
              key={soup.id}
              style={{ backgroundImage: `url(${bgImage})` }}
              // ref={ref}
            >
              <Img
                src={require(`../images/${soup.id}-side.jpg`).default}
                alt="hotpot"
              />
              {idx % 2 ? (
                <LeftText
                  // initial={{ opacity: 0 }}
                  // animate={inView ? "hidden" : "visible"}
                  // variants={fadeVariants}
                >
                  <h3>{soup.name}</h3>
                </LeftText>
              ) : (
                <RightText>
                  <h3>{soup.name}</h3>
                </RightText>
              )}
            </Section>
          // )}
        // </InView>
      ))}
    </div>
  );
}

export default Soup;
