import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { useScrollTrigger } from "@mui/material";
// import BBCow from '../images/BBCow.png'
import logo from "../images/logo-horizontal.png";
import { APP_CONSTANTS } from "../data/data";
import styled from "@emotion/styled";

const TopButton = styled(Button)`
  border: 1px solid var(--gold);
  border-radius: 20px;
  background: var(--gold);
  color: white;
  margin: 5px;
`;

function Navbar() {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 30 });
  //   console.log(trigger);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <AppBar position="sticky" color="transparent" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar>
          {/* <Typography
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            Logo
          </Typography> */}
          {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton onClick={handleOpenMenu}>
              <MenuIcon />
            </IconButton>
          </Box> */}
          {/* <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            {APP_CONSTANTS.actionButtons.map((button) => (
              <Button
                variant="contained"
                disableElevation
                href={button.link}
                target={"_blank"}
                sx={{
                    border: "1px solid var(--gold)",
                    borderRadius: "20px",
                    background: "var(--gold)",
                    color: "white",
                    margin: '5px',
                    ":hover": {
                        background: "var(--gold)",
                        color: "white",
                      },
                  }}
              >
                {button.text}
              </Button>
            ))}
          </Box> */}

          <Menu
            id="menu-appbar"
            anchorEl={anchorElMenu}
            keepMounted
            open={Boolean(anchorElMenu)}
            onClose={handleCloseMenu}
          >
            {APP_CONSTANTS.navbar_items_zh.map((navItem, idx) => (
              <Link
                to={navItem.path}
                style={{ textDecoration: "none", color: "var(--raisin)" }}
              >
                <MenuItem key={navItem.name} onClick={handleCloseMenu}>
                  {navItem.name}
                </MenuItem>
              </Link>
            ))}
          </Menu>
        
        {/* logo */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: "10vw", margin: "1rem", mixBlendMode: "overlay" }}
            />
          </Box>

{/* menu */}
          <Box
            sx={{
              flexGrow: 1,
              // display: { xs: "none", md: "flex" },
              display: { xs: "flex" },
              justifyContent: "space-evenly",
              // justifyContent: 'flex-start'
            }}
          >
            {APP_CONSTANTS.navbar_items_zh.map((page) => (
              // <Button key={page.name} onClick={() => {}}>
              <Link
                key={page.name}
                to={page.path}
                style={{
                  color: trigger ? "white" : "var(--raisin)",
                  textDecoration: "none",
                  fontWeight: "bold",
                  margin: '1rem'
                  //   ":hover": {
                  //     color: trigger ? "var(--gold)" : "white",
                  //   },
                }}
              >
                {page.name}
              </Link>
              //  </Button>
            ))}
          </Box>


          <Box
            sx={{
              flexGrow: 1,
              // display: { xs: "none", md: "flex" },    
              display: {xs: 'flex'},
              justifyContent: "flex-end",
            }}
          >
            {APP_CONSTANTS.actionButtons.map((button) => (
              <Box sx={{ mx: 1 }}>
                <Button
                  variant="contained"
                  disableElevation
                  href={button.link}
                  target={"_blank"}
                  sx={{
                    border: "1px solid var(--gold)",
                    borderRadius: "20px",
                    background: "white",
                    color: "var(--gold)",
                    ":hover": {
                      background: "var(--gold)",
                      color: "white",
                    },
                  }}
                >
                  {button.text}
                </Button>
              </Box>
            ))}

            {/* <Box sx={{ mx: 1 }}>
              <InstagramIcon />
            </Box>
            <Box sx={{ mx: 1 }}>
              <FacebookIcon />
            </Box> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
