import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { createTheme } from "@mui/system";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";

const customTheme = createTheme({
  palette: {
    bbtgold: {
      main: "#cbbd3f",
      contrastText: "darkslategray",
    },
    bbtblack: {
      main: "#231f20",
      contrastText: "white",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={customTheme}> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </ThemeProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// cbbd3f
