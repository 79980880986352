export const APP_CONSTANTS = {
  name_zh: "牛品台式火鍋",
  name_en: "Beef Bang Taiwansese Hotpot",
  booking_phone: "2701 8928",
  email: "info@beefbang.com.hk",
  opening_hour_text_zh: "營業時間",
  opening_hour_text_en: "Opening Hour",
  opening_hour: "12:00 - 22:00",
  address_zh: "九龍尖沙咀樂道32-34號1樓",
  address_en: "1/F, 32-34 Lock Road, Tsim Sha Tsui, Kowloon",
  navbar_items_zh: [
    { name: "主頁", path: "/" },
    { name: "湯底", path: "/soup" },
    { name: "套餐", path: "/menu" },

    // { name: "最新消息", path: "/news" },
    // { name: "關於我們", path: "/about" },
  ],
  navbar_items_en: ["Home", "Menu", "News", "About Us"],

  booking_zh: "訂座",
  delivery_zh: "外賣",
  queue_zh: "取票",
  facebook_link: "",
  instagram_link: "https://www.instagram.com/beefbang_hk/",
  booking_link:
    "https://inline.app/booking/-MpnklELL3MjSAqgzTf_:inline-live-2/-MpnklQ3reeZkxu5yAG6?language=zh-hk",
  delivery_link: "",
  queue_up_link:
    "https://inline.app/booking/-MpnklELL3MjSAqgzTf_:inline-live-2/-MpnklQ3reeZkxu5yAG6?language=zh-hk",

  actionButtons: [
    // {
    //   text: "取票",
    //   link: "https://inline.app/booking/-MpnklELL3MjSAqgzTf_:inline-live-2/-MpnklQ3reeZkxu5yAG6?language=zh-hk",
    // },
    {
      text: "訂座",
      link: "https://inline.app/booking/-MpnklELL3MjSAqgzTf_:inline-live-2/-MpnklQ3reeZkxu5yAG6?language=zh-hk",
    },
    // { text: "外賣", link: "" },
  ],
  pot: [
    { name: "元氣養生雞湯鍋", id: "s1" },
    { name: "剝皮辣椒雞鍋", id: "s2" },
    { name: "南投烏龍奶茶鍋", id: "s3" },
    { name: "柴魚昆布鍋", id: "s4" },
    // { name: "桃膠藥膳養生鍋", id: "s5" },
    { name: "沙茶海鮮鍋", id: "s6" },
    { name: "牛品一品鍋", id: "s7" },
    { name: "牛品秘製麻辣鍋", id: "s8" },
    // { name: "牛奶美人豆腐鍋", id: "s9" },
    { name: "花菇昆布鍋（素）", id: "s10" },
    { name: "蕃茄濃湯鍋", id: "s11" },
    // { name: "藤椒酸魚湯", id: "s12" },
    { name: "酸菜白肉鍋", id: "s13" },
    { name: "黑蒜黃金蜆鍋", id: "s14" },
  ],
};
