import styled from "@emotion/styled";
import React from "react";
import { Box } from "@mui/material";

// import lunchSet1 from "../images/menu/lunch-set-1.jpg";
import lunchSet2 from "../images/menu/lunch-set-2.jpg";

import lunch1 from "../images/menu/lunch-60-1.jpg";
import lunch2 from "../images/menu/lunch-60-2.jpg";
import lunch3 from "../images/menu/90-lunch-1.jpg";
import lunch4 from "../images/menu/90-lunch-2.jpg";

import dinner1 from "../images/menu/dinner/120-dinner-1.jpg";
import dinner2 from "../images/menu/dinner/120-dinner-2.jpg";
import dinner3 from "../images/menu/dinner/120-dinner-3.jpg";
import dinner4 from "../images/menu/dinner/120-dinner-4.jpg";

import bgImage from "../images/bg.jpg";

const MenuImage = styled.img`
  width: 90%;
  /* height: 80%; */
  object-fit: cover;
`;
const Section = styled.section`
  /* margin: 0; */
  margin: 20px auto;
  width: 90vw;
  /* height: 100vh; */
  /* position: relative;
  background-image: url("../images/bg.jpg");
  background-repeat: repeat;
  background-size: cover; */
`;

const BoxStyle = {
  paddingTop: "3vh",
  paddingBottom: "200px",

  minHeight: "100vh",
  // backgroundColor: 'rgba(0,0,0,0.1)',
  backgroundImage: `url(${bgImage})`,
  backgroundSize: "100%",
  backgroundRepeat: "repeat",
};
function Menu() {
  return (
    <Box
      sx={{ display: { xs: "flex", md: "block" }, flexDirection: "column" }}
      style={BoxStyle}
    >
      {/* <Section>
        <MenuImage src={discount88} alt="special discount $88 60 minutes" />
      </Section> */}
      {/* <Section>
        <MenuImage src={lunchSet1} alt="lunch set" />
      </Section> */}
      <Section>
        <MenuImage src={lunchSet2} alt="lunch set" />
      </Section>
      <Section>
        <MenuImage src={lunch1} alt="lunch 60 minutes" />
      </Section>
      <Section>
        <MenuImage src={lunch2} alt="lunch 60 minutes" />
      </Section>
      <Section>
        <MenuImage src={lunch3} alt="lunch 90 minutes" />
      </Section>
      <Section>
        <MenuImage src={lunch4} alt="lunch 90 minutes" />
      </Section>
      <Section>
        <MenuImage src={dinner1} alt="dinner 120 minutes" />
      </Section>
      <Section>
        <MenuImage src={dinner2} alt="dinner 120 minutes" />
      </Section>
      <Section>
        <MenuImage src={dinner3} alt="dinner 120 minutes" />
      </Section>
      <Section>
        <MenuImage src={dinner4} alt="dinner 120 minutes" />
      </Section>
    </Box>
  );
}

export default Menu;
